exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-templates-project-tsx-content-file-path-github-workspace-gatsby-app-src-data-projects-instagram-app-index-mdx": () => import("./../../../src/templates/project.tsx?__contentFilePath=/github/workspace/gatsby-app/src/data/projects/instagram-app/index.mdx" /* webpackChunkName: "component---src-templates-project-tsx-content-file-path-github-workspace-gatsby-app-src-data-projects-instagram-app-index-mdx" */),
  "component---src-templates-project-tsx-content-file-path-github-workspace-gatsby-app-src-data-projects-kanban-boards-index-mdx": () => import("./../../../src/templates/project.tsx?__contentFilePath=/github/workspace/gatsby-app/src/data/projects/kanban-boards/index.mdx" /* webpackChunkName: "component---src-templates-project-tsx-content-file-path-github-workspace-gatsby-app-src-data-projects-kanban-boards-index-mdx" */),
  "component---src-templates-project-tsx-content-file-path-github-workspace-gatsby-app-src-data-projects-movie-app-index-mdx": () => import("./../../../src/templates/project.tsx?__contentFilePath=/github/workspace/gatsby-app/src/data/projects/movie-app/index.mdx" /* webpackChunkName: "component---src-templates-project-tsx-content-file-path-github-workspace-gatsby-app-src-data-projects-movie-app-index-mdx" */),
  "component---src-templates-project-tsx-content-file-path-github-workspace-gatsby-app-src-data-projects-portfolio-website-index-mdx": () => import("./../../../src/templates/project.tsx?__contentFilePath=/github/workspace/gatsby-app/src/data/projects/portfolio-website/index.mdx" /* webpackChunkName: "component---src-templates-project-tsx-content-file-path-github-workspace-gatsby-app-src-data-projects-portfolio-website-index-mdx" */),
  "component---src-templates-project-tsx-content-file-path-github-workspace-gatsby-app-src-data-projects-trillo-shop-index-mdx": () => import("./../../../src/templates/project.tsx?__contentFilePath=/github/workspace/gatsby-app/src/data/projects/trillo-shop/index.mdx" /* webpackChunkName: "component---src-templates-project-tsx-content-file-path-github-workspace-gatsby-app-src-data-projects-trillo-shop-index-mdx" */)
}

